import React, { useState, useRef } from 'react';
import Button from '../button';
import Policy from './policy';
import Contacts from './contacts';
import style from './index.module.scss';

const Footer = () => {
	const [showModal, setShowModal] = useState(false);
	const [showContacts, setShowContacts] = useState(false);
	const el = useRef(null);
	const contactsEl = useRef(null);

	function openModal() {
		setShowModal(true);
	}

	function closeModal() {
		setShowModal(false);
	}

	function openContacts() {
		setShowContacts(true);
	}

	function closeContacts() {
		setShowContacts(false);
	}

	return (
		<>
			<Policy closeModal={closeModal} modalRef={el.current} showModal={showModal} />
			<Contacts closeContacts={closeContacts} refContacts={contactsEl.current} showContacts={showContacts} />
			<div id="footer" className={style.footer}>
				<div className={style.contacts}>
					<p>
						<b>Адрес:</b> г. Кемерово, пр-т Ленина 59/1, ТРК&nbsp;"Променад-3" (новый корпус, 1 этаж)
					</p>
					<p className={style.hours}>
						<b>Часы работы:</b> <br/> <span>Будни: с 10 до 19 <br/>Суббота: с 11 до 17<br/> Воскресенье: выходной</span>
					</p>
					<div className={style.tel}>
						<b>Телефоны:</b>
						<div className={style.numbers}>
							<a href="tel:+73842900505">+7 3842 90-05-05</a>
							<a href="tel:+79515900505">+7 951 590-05-05</a>
						</div>
					</div>
					<p className={style.mail}>
						<b>Почта:</b> <br></br>
						<a href="mailto:office@turkemerovo.ru">office@turkemerovo.ru</a>
					</p>
				</div>

				<div className={style.socials}>
					<a title="Telegram" href="https://t.me/Turkemerovo">
						<i className="icon-telegram"></i>
					</a>
					<a title="WhatsApp" href="https://wa.me/79515900505">
						<i className="icon-whatsapp"></i>
					</a>
					<a title="Viber" href="https://viber.click/79515900505">
						<i className="icon-viber"></i>
					</a>
				</div>
				<div className={style.buttons}>
					<Button reset={true} className={style.policy} ref={el} onClick={openModal}>
						Политика конфиденциальности
					</Button>
					<Button reset={true} className={style.policy} ref={contactsEl} onClick={openContacts}>
						Реквизиты компании
					</Button>
				</div>
				<div className={style.logo}>
					<div className={style.logoImage}></div>
					<div className={style.logoText}>Туркемерово, ООО</div>
				</div>
				<small>
					Все цены, даты и прочие параметры тура, опубликованные на сайте turkemerovo.ru, не являются офертой.
					Актуальную информацию уточняйте у сотрудников компании.
				</small>
			</div>
		</>
	);
};

export default Footer;
