import React, { useEffect } from 'react';

const Flamp = () => {
	function flamp(d, s) {
		var js,
			fjs = d.getElementsByTagName(s)[0];
		js = d.createElement(s);
		js.async = 1;
		js.src = '//widget.flamp.ru/loader.js';
		fjs.parentNode.insertBefore(js, fjs);
	}

	useEffect(() => {
		flamp(document, 'script');
	}, []);

	return (
		<>
			<div id="flamp"></div>
			<a
				className="flamp-widget"
				href="//kemerovo.flamp.ru/firm/coral_travel_turagentstvo-70000001027944787"
				data-flamp-widget-type="responsive-new"
				data-flamp-widget-id="70000001027944787"
				data-flamp-widget-width="100%"
				data-flamp-widget-count="1"
			>
				Отзывы о нас на Флампе
			</a>
		</>
	);
};

export default Flamp;
