export const DEBOUNCE_DEFAULT_DELAY = 200;

export default function debounce(func, delay = DEBOUNCE_DEFAULT_DELAY) {
	let timeout;

	return function (...args) {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			timeout = null;
			func.apply(this, args);
		}, delay);
	};
}
