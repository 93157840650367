import React, { useEffect } from 'react';

const Widget = () => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = '//tourvisor.ru/module/init.js';
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return <div id="widget" style={{ marginBottom: '48px' }} className="tv-search-form tv-moduleid-199124"></div>;
};

export default Widget;
