import React from 'react';
import PropTypes from 'prop-types';

import style from './index.module.scss';

const Button = React.forwardRef(({ children, reset, className, type, href, onClick, disabled, ...attrs }, ref) => {
	let Tag = 'button';
	if (href) Tag = 'a';
	const buttonClass = reset ? [className, style.reset].join(' ') : [className, style.button].join(' ');

	const onBtnClick = (e) => {
		if (disabled) {
			e.preventDefault();
		} else {
			onClick(e);
		}
	};

	return (
		<Tag ref={ref} type={type} className={buttonClass} onClick={onBtnClick} disabled={disabled} {...attrs}>
			{children}
		</Tag>
	);
});

Button.defaultProps = {
	children: '',
	className: '',
	type: 'button',
	href: null,
	disabled: false,
	reset: false,
};

Button.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	type: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	reset: PropTypes.bool,
};

export default Button;
