import React from 'react';
import Lottie from 'lottie-react-web';
import style from './index.module.scss';
import couple from '../../animations/couple.json';
import sun from '../../animations/sun.json';
import check from '../../animations/check-blue.json';
import trophy from '../../animations/trophy.json';

const Pros = () => {
	return (
		<div id="pros" className={style.pros}>
			<h2 data-sal="slide-bottom" data-sal-delay="100" data-sal-duration="500" data-sal-easing="ease-out">
				Почему выбирают нас
			</h2>
			<div className={style.prosContainer}>
				<div className={style.pro}>
					<div className={style.proMedia}>
						<Lottie
							ariaRole="img"
							speed={0.5}
							width="50%"
							options={{
								animationData: trophy,
							}}
						/>
					</div>
					<h4
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						Богатый опыт
					</h4>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						На рынке туризма работаем более 9 лет. Стабильно входим в ТОП российских турагентств по продаже
						туров от туроператора Coral Travel, что подтверждается дипломами престижной премии в сфере туризма "Starway"
					</p>
				</div>

				<div className={style.pro}>
					<div className={style.proMedia}>
						<Lottie
							ariaRole="img"
							width="50%"
							options={{
								animationData: sun,
							}}
						/>
					</div>
					<h4
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						проверенные партнеры
					</h4>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Напрямую сотрудничаем с крупнейшими российскими туроператорами Coral Travel, Anex Tour, Pegas Touristik,
						Fun and Sun, Sunmar, Tez Tour и многими другими.
					</p>
				</div>

				<div className={style.pro}>
					<div className={style.proMedia}>
						<Lottie
							ariaRole="img"
							width="40%"
							speed={0.5}
							options={{
								animationData: check,
							}}
						/>
					</div>
					<h4
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						Мы умеем слушать
					</h4>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="700"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Строго придерживаемся принципа "ожидание-реальность" в работе. Поэтому скрупулезно узнаем у клиентов про то
						что, они хотят получить от отдыха и подбираем вариант, который на 100% будет соответствовать ожиданиям.
					</p>
				</div>

				<div className={style.pro}>
					<div className={style.proMedia}>
						<Lottie
							ariaRole="img"
							width="100%"
							options={{
								animationData: couple,
							}}
						/>
					</div>
					<h4
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						Беззаботный отдых
					</h4>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="700"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Сопровождаем клиента от момента первого обращения в нашу компанию до возврата с отдыха. Именно поэтому
						количество клиентов, которых может курировать каждый трэвэл-агент строго ограничено: количество не должно
						вредить качеству.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Pros;
