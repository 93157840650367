import React from 'react';
import Form from '../form';

import style from './index.module.scss';

const Map = () => {
	return (
		<div id="map" className={style.map}>
			<iframe
				title="yandex-map"
				src="https://yandex.ru/map-widget/v1/?um=constructor%3A48d74643ab7a795d3409c5835043708a3e85f0fc294fe57c3fba97d58495e1d1&amp;source=constructor"
				width="100%"
				height="400"
				frameBorder="0"
			></iframe>
			<Form buttonText="Позвоните мне" label="Заказать звонок" id="map" className={style.form} />
		</div>
	);
};

export default Map;
