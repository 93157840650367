import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import Intro from '../intro';
import Button from '../button';
import Ok from '../ok';
import TextField from '../text-field';
import Lottie from 'lottie-react-web';

import checklist from '../../animations/checklist.json';
import { validatePhone } from '../../utils/validation';
import style from './index.module.scss';

const modalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '0 2px 13px rgba(0, 0, 0, 0.2)',
		padding: '16px',
		maxHeight: '80vh',
		maxWidth: '600px',
		borderRadius: '8px',
		border: 'none',
	},
};

const FirstScreen = () => {
	const [showModal, setShowModal] = useState(false);
	const [showOk, setShowOk] = useState(false);
	const [pending, setPending] = useState(false);
	const [name, setName] = useState('');
	const [tel, setTel] = useState('');
	const [message, setMessage] = useState('');
	const el = useRef(null);
	const timeStamp = new Date().getTime();

	const postMessage = async (e, u_name, u_phone, u_note, r_u_id = 3773) => {
		e.preventDefault();
		if (u_name === '' || u_phone === '' || u_note.length < 6) {
			alert('Пожалуйста, заполните все поля!');
			return;
		}
		if (!validatePhone(u_phone)) {
			alert('Проверьте указанный номер телефона');
			return;
		}
		setPending(true);
		try {
			const res = await fetch('/.netlify/functions/lead-create', {
				headers: {
					'content-type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({
					r_id_internal: timeStamp,
					r_u_id,
					source: 'Заявка с сайта',
					u_name,
					u_phone,
					u_note,
				}),
			});

			if (res.ok) {
				setShowOk(true);
				setTimeout(() => setShowOk(false), 2200);
			} else {
				alert('Произошла ошибка, сообщение не отправлено');
			}
		} catch (error) {
			alert('Ошибка отправки данных');
		} finally {
			setShowModal(false);
			setPending(false);
		}
	};

	return (
		<div id="first" className={style.first}>
			{showOk && <Ok />}
			<Modal
				shouldReturnFocusAfterClose={true}
				appElement={el.current}
				isOpen={showModal}
				onRequestClose={() => setShowModal(false)}
				style={modalStyles}
				contentLabel="Заказать тур"
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
					<div className={style.modalHeader}>
						<h3>Заказать тур</h3>
					</div>
					<button className={style.modalClose} onClick={() => setShowModal(false)}>
						<i className="icon-cross"></i>
					</button>
				</div>
				<div className={style.formDescription}>
					<div className={style.formIcon}>
						<Lottie
							ariaLabel="Анимированный чеклист"
							ariaRole="img"
							speed={0.5}
							width="100%"
							options={{
								animationData: checklist,
							}}
						/>
					</div>

					<p className={style.descriptionText}>
						Когда вы планируете отправиться в путешествие? Чем будете заниматься на отдыхе? Поедут ли с вами дети?
						Расскажите нам о том, что для вас важно при выборе тура, чтобы наш специалист смог подобрать идеальный
						вариант именно для вас.
					</p>
				</div>

				<form className={style.form}>
					<div className={style.inputContainer}>
						<TextField
							value={name}
							onChange={(e) => setName(e.target.value)}
							className={style.input}
							id="first-form-name"
							type="text"
							placeholder="Ваше имя"
						/>
						<TextField
							value={tel}
							onChange={(e) => setTel(e.target.value)}
							className={style.input}
							id="first-form-tel"
							type="tel"
							placeholder="Ваш номер телефона"
						/>
					</div>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className={style.area}
						id="first-form-message"
						type="text"
						rows={4}
						placeholder="Ваши пожелания"
					/>

					<Button
						disabled={pending}
						style={{ width: '100%', height: '52px' }}
						type="submit"
						onClick={(e) => postMessage(e, name, tel, message)}
					>
						{pending ? 'Отправляем запрос...' : 'Заказать'}
					</Button>
				</form>
			</Modal>
			<Intro />
			<div className={style.action}>
				<h2 className={style.descript}>Первый шаг к&nbsp;лучшему отдыху</h2>
				<Button className={style.actionButton} onClick={() => setShowModal(true)}>
					<span ref={el}>Заказать тур</span>
				</Button>
			</div>
			<button
				className={style.buttonArrow}
				onClick={() => {
					const nav = document.querySelector('nav');
					const widget = document.querySelector('#first').getBoundingClientRect().bottom;
					window.scrollTo({
						top: widget - nav.offsetHeight,
						left: 0,
						behavior: 'smooth',
					});
				}}
			>
				<span className={style.arrow}></span>
			</button>
		</div>
	);
};

export default FirstScreen;
