import React, { useState } from 'react';
import Button from '../button';
import TextField from '../text-field';
import Image from '../image';
import { validateEmail } from '../../utils/validation';
import style from './index.module.scss';

const Special = () => {
	const [success, setSuccess] = useState(false);
	const [mail, setMail] = useState('');
	const [name, setName] = useState('');
	const [pending, setPending] = useState(false);
	const timeStamp = new Date().getTime();

	const postMessage = async (e, u_name, u_email) => {
		e.preventDefault();
		if (u_name === '' || u_email === '') {
			alert('Пожалуйста, заполните все поля!');
			return;
		}
		if (!validateEmail(u_email)) {
			alert('Проверьте указанную почту');
			return;
		}
		setPending(true);
		try {
			const res = await fetch('/.netlify/functions/lead-create', {
				headers: {
					'content-type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({
					r_id_internal: timeStamp,
					source: 'Заявка с сайта',
					u_name,
					u_email,
				}),
			});

			if (res.ok) {
				setSuccess(true);
			} else {
				alert('Произошла ошибка, сообщение не отправлено');
			}
		} catch (error) {
			alert('Ошибка отправки данных');
		} finally {
			setPending(false);
		}
	};

	return (
		<div id="special" className={style.special}>
			<Image className={style.image} />
			<div className={style.container}>
				<h2 data-sal="slide-bottom" data-sal-delay="300" data-sal-duration="500" data-sal-easing="ease-out">
					Специальные предложения
				</h2>
				<p data-sal="slide-bottom" data-sal-delay="500" data-sal-duration="500" data-sal-easing="ease-out">
					Горящие туры, полезные лайфхаки, закрытые акции и розыгрыши, реальные истории наших туристов, актуальные
					новости — все самое полезное для твоего будущего отдыха в еженедельном трэвэл-информаторе. Подпишись!
				</p>
				<form
					autoComplete="on"
					data-sal="slide-bottom"
					data-sal-delay="700"
					data-sal-duration="500"
					data-sal-easing="ease-out"
				>
					<TextField
						onChange={(e) => setName(e.target.value)}
						className={style.name}
						disabled={success}
						value={name}
						id="special-name"
						type="text"
						placeholder="Представьтесь"
						autoComplete="on"
					/>
					<TextField
						onChange={(e) => setMail(e.target.value)}
						className={style.mail}
						disabled={success}
						value={mail}
						id="special-email"
						type="email"
						placeholder="Укажите ваш email"
						autoComplete="on"
					/>
					<Button
						type="sumbit"
						disabled={success}
						className={!success ? style.btn : [style.btn, style.btnSuccess].join(' ')}
						onClick={(e) => postMessage(e, name, mail)}
					>
						{!success ? (pending ? 'Отправляем запрос' : 'Подписаться') : 'Успешно!'}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default Special;
