export function validateEmail(mail: string): boolean {
	return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(mail);
}

export function validatePhone(tel: string): boolean {
	return /^\+?\d{6,}$/.test(tel);
}

export function validateName(name: string): boolean {
	return name.length > 0;
}
