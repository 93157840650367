import React, { useState, useEffect } from 'react';
import video from '../../assets/video/video_steps.mp4';
import videoMobile from '../../assets/video/video_steps_mobile.mp4';
import style from './index.module.scss';
import snapshot from '../../assets/video/snapshot.jpg';
import snapshotMobile from '../../assets/video/snapshot_mobile.jpg';

const Intro = () => {
	const [videoSrc, setVideoSrc] = useState(videoMobile);
	const [snapShotSrc, setSnapShotSrc] = useState(snapshotMobile);

	useEffect(() => {
		window.innerWidth < 563 ? setVideoSrc(videoMobile) : setVideoSrc(video);
		window.innerWidth < 563 ? setSnapShotSrc(snapshotMobile) : setSnapShotSrc(snapshot);
	}, []);

	return (
		<div className={style.video}>
			<video
				poster={snapShotSrc}
				playsInline={true}
				className={style.media}
				loop={true}
				autoPlay={true}
				muted={true}
				src={videoSrc}
			></video>
		</div>
	);
};

export default Intro;
