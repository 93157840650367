import React from 'react';
import Lottie from 'lottie-react-web';
import style from './index.module.scss';
import send from '../../animations/send-it.json';

const Ok = () => {
	return (
		<div className={style.ok}>
			<Lottie
				ariaRole="img"
				speed={0.75}
				width="200"
				options={{
					animationData: send,
				}}
			/>
		</div>
	);
};

export default Ok;
