import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ className }) => {
	const data = useStaticQuery(graphql`
		query {
			Image: file(relativePath: { eq: "special.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200, quality: 80) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Img
			style={{ position: 'relative', height: '450px', zIndex: '-1' }}
			className={className}
			fluid={data.Image.childImageSharp.fluid}
		/>
	);
};

export default Image;
