import React, { useState } from 'react';
import TextField from '../text-field';
import Button from '../button';
import { validatePhone } from '../../utils/validation';

import style from './index.module.scss';

const Form = ({ buttonText = 'Отправить заявку', label = 'Заказать тур', id, withMessage, className }) => {
	const [success, setSuccess] = useState(false);
	const [pending, setPending] = useState(false);
	const [uname, setUname] = useState('');
	const [tel, setTel] = useState('');
	const timeStamp = new Date().getTime();

	const postMessage = async (e, u_name, u_phone) => {
		e.preventDefault();
		if (u_name === '' || u_phone === '') {
			alert('Пожалуйста, заполните все поля!');
			return;
		}
		if (!validatePhone(u_phone)) {
			alert('Проверьте указанный номер телефона');
			return;
		}
		setPending(true);
		try {
			const res = await fetch('/.netlify/functions/lead-create', {
				headers: {
					'content-type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({
					r_id_internal: timeStamp,
					r_u_id: 3773,
					source: 'Заявка с сайта',
					u_name,
					u_phone,
				}),
			});

			if (res.ok) {
				setSuccess(true);
			} else {
				alert('Произошла ошибка, сообщение не отправлено');
			}
		} catch (error) {
			alert('Ошибка отправки данных');
		} finally {
			setPending(false);
		}
	};

	return (
		<form className={[className, style.form].join(' ')}>
			<h3>{label}</h3>
			<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '14px' }}>
				<TextField
					value={uname}
					disabled={success}
					onChange={(e) => setUname(e.target.value)}
					id={id + '-form-name'}
					type="text"
					placeholder="Ваше имя"
				/>
				<TextField
					disabled={success}
					value={tel}
					onChange={(e) => setTel(e.target.value)}
					id={id + '-form-tel'}
					type="text"
					placeholder="Ваш номер телефона"
				/>
			</div>

			{withMessage && (
				<textarea className={style.area} id={id + '-form-message'} type="text" rows={4} placeholder="Ваше сообщение" />
			)}
			<Button disabled={success} type="button" onClick={(e) => postMessage(e, uname, tel)}>
				{success ? 'Мы вам перезвоним!' : pending ? 'Отправляем...' : buttonText}
			</Button>
		</form>
	);
};

export default Form;
