import React, { useState, useEffect } from 'react';

import style from './index.module.scss';

const Step = ({ step, steps, question, setAnswers, answers }) => {
	const [ans, setAns] = useState('');

	const saveAnswer = (e) => {
		e.persist();
		setAns(e.target.value);
		setAnswers((prevState) => {
			const newState = [...prevState];
			newState[step] = {
				question: question.question,
				answer: e.target.value,
			};
			return newState;
		});
	};

	useEffect(() => {
		if (answers[step]) setAns(answers[step].answer);
	}, [step, answers]);

	return (
		<div className={style.wizardStep}>
			<small className={style.question}>
				{step + 1}/{steps}
			</small>
			<h4 className={style.question}>{question.question}</h4>
			<div className={style.answers}>
				{question.answers.map((answer) => {
					return (
						<div key={answer}>
							<input
								className={style.answerInput}
								id={answer}
								style={{ display: 'none' }}
								onChange={saveAnswer}
								type="radio"
								name={`question${step}`}
								value={answer}
								checked={ans === answer}
							/>
							<label htmlFor={answer} className={style.answerLabel}>
								{answer}
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Step;
