import React from 'react';
import Modal from 'react-modal';
import style from './index.module.scss';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '0 2px 13px rgba(0, 0, 0, 0.2)',
		padding: '16px',
		height: '450px',
		width: '90wv',
		maxWidth: '760px',
		borderRadius: '8px',
		border: 'none',
		zIndex: '1000',
	},
};

const Contacts = ({ closeContacts, refContacts, showContacts }) => {
	return (
		<Modal
			shouldReturnFocusAfterClose={true}
			portalClassName={style.portal}
			appElement={refContacts}
			isOpen={showContacts}
			onRequestClose={closeContacts}
			style={customStyles}
			contentLabel="Реквизиты компании"
		>
			<div className={style.contactsModal}>
				<h4>ООО "Туркемерово"</h4>
				<p>
					<b>Юридический и почтовый адрес:</b> 650066, г. Кемерово, Пр-т Ленина 59/1, помещение&nbsp;2{' '}
				</p>
				<p>
					<b>Расчетный счет:</b> 40702810023170000946
				</p>
				<p>
					<b>Кор.счет:</b> 30101810600000000774
				</p>
				<p>
					<b>Банк:</b> ФИЛИАЛ "НОВОСИБИРСКИЙ" АО "АЛЬФА-БАНК" Г. НОВОСИБИРСК
				</p>
				<p>
					<b>БИК:</b> 045004774
				</p>
				<p>
					<b>ИНН:</b> 4205368586
				</p>
				<p>
					<b>КПП:</b> 420501001
				</p>
				<p>
					<b>ОГРН: </b>1184205009273
				</p>
				<p>
					<b>ОКПО:</b> 28468408
				</p>
				<p>
					<b>Директор:</b> Алексей Евгеньевич Пустовит
				</p>
			</div>
		</Modal>
	);
};

export default Contacts;
