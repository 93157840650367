import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Expert from '../expert';

import style from './index.module.scss';

const Experts = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulExpert {
				edges {
					node {
						description
						name
						telegram
						telephone
						uOnId
						photo {
							fluid(maxWidth: 400, quality: 80) {
								src
							}
						}
						id
					}
				}
			}
		}
	`);

	return (
		<div id="experts" className={style.container}>
			<h2>Наши трэвэл-агенты</h2>
			<div className={style.experts}>
				{data.allContentfulExpert.edges.map((edge) => {
					return <Expert key={edge.node.id} info={edge.node} />;
				})}
			</div>
		</div>
	);
};

export default Experts;
