import React, { useState } from 'react';
import SEO from '../components/seo';
import Header from '../components/header';
import FirstScreen from '../components/first-screen';
import Pros from '../components/pros';
import Experts from '../components/experts';
import Footer from '../components/footer';
import Widget from '../components/widget';
import Special from '../components/special';
import Office from '../components/office';
import Map from '../components/map';
import Flamp from '../components/flamp';
import Wizard from '../components/wizard';
import Menu from '../components/menu';

const IndexPage = () => {
	const [showModal, setShowModal] = useState(false);
	const [nav, setNav] = useState(null);

	return (
		<div style={{ overflowX: 'hidden', pointerEvents: 'all' }}>
			<Header showModal={showModal} setNav={setNav} setShowModal={setShowModal} />
			{showModal && <Menu nav={nav} setShowModal={setShowModal} />}
			<FirstScreen />
			<main style={{ overflowX: 'hidden', padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
				<SEO />
				<Widget />
				<Pros />
				<Special />
				<Experts />
				<Wizard />
				<Office />
				<Flamp />
			</main>
			<Map />
			<Footer />
		</div>
	);
};

export default IndexPage;
