import React, { useRef, useState, useEffect } from 'react';
import Button from '../button';
import contacts from '../../data/contacts.json';
import smoothScrollTo from '../../utils/smoothScroll';

import style from './index.module.scss';

const Header = ({ setShowModal, showModal, setNav }) => {
	const nav = useRef(null);
	const [navClass, setNavClass] = useState(style.header);
	const [menuClass, setMenuClass] = useState(style.hamburger);

	const fixNav = () => {
		if (window.scrollY > nav.current.offsetHeight) {
			setNavClass([style.header, style.fixed].join(' '));
		} else {
			setNavClass(style.header);
		}
	};

	const toggleMenu = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		fixNav();
		window.addEventListener('scroll', fixNav);
		return () => {
			window.removeEventListener('scroll', fixNav);
		};
	}, []);

	useEffect(() => {
		setNav(nav);
	}, [setNav]);

	useEffect(() => {
		if (showModal) {
			setMenuClass([style.hamburger, style.hamburgerActive].join(' '));
		} else {
			setMenuClass(style.hamburger);
		}
	}, [showModal]);

	return (
		<nav ref={nav} className={navClass}>
			<Button
				reset={true}
				className={style.logo}
				onClick={(e) => {
					e.preventDefault();
					smoothScrollTo('.begin');
				}}
				alt="Логотип Coral Travel"
			>
				<span className={style.logoText}>турагентство</span>
			</Button>
			<ul className={style.list}>
				<li>
					<Button reset={true} className={style.link} onClick={() => smoothScrollTo('#pros', nav)}>
						О нас
					</Button>
				</li>
				<li>
					<Button reset={true} className={style.link} onClick={() => smoothScrollTo('#flamp', nav)}>
						Отзывы
					</Button>
				</li>
				<li>
					<Button reset={true} className={style.link} onClick={() => smoothScrollTo('#widget', nav)}>
						Найти тур
					</Button>
				</li>
				<li>
					<Button reset={true} className={style.link} onClick={() => smoothScrollTo('#map', nav)}>
						Как доехать
					</Button>
				</li>
			</ul>
			<div className={style.contacts}>
				<a className={style.number} href={'tel:' + contacts.tel}>
					{contacts['tel-format']}
				</a>
				<div className={style.address}>{contacts.addr}</div>
			</div>

			<button className={menuClass} onClick={() => toggleMenu()}>
				<span className={style.hamburger__box}>
					<span className={style.hamburger__inner}></span>
				</span>
			</button>
		</nav>
	);
};

export default Header;
