export default function smoothScrollTo(selector, nav) {
	const el = document.querySelector(selector);
	let top;
	top = el ? el.offsetTop - nav.current.offsetHeight : 0;
	window.scrollTo({
		left: 0,
		top: top,
		behavior: 'smooth',
	});
}
