import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import Ok from '../ok';
import Button from '../../components/button';
import TextField from '../../components/text-field';
import { validatePhone } from '../../utils/validation';

import style from './index.module.scss';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '0 2px 13px rgba(0, 0, 0, 0.2)',
		padding: '16px',
		maxHeight: '80vh',
		maxWidth: '600px',
		borderRadius: '8px',
		border: 'none',
	},
};

const Expert = ({ info }) => {
	const { id, name, description, telephone, telegram, photo, uOnId } = info;
	const formattedTel = telephone.toString()[0] === '8' ? '7' + telephone.toString().substr(1) : telephone.toString();
	const telHref = `tel:${formattedTel}`;
	const telegramHref = `https://t.me/${telegram}`;
	const whatsAppHref = `https://wa.me/${formattedTel}`;
	const [showModal, setShowModal] = useState(false);
	const el = useRef(null);

	const [showOk, setShowOk] = useState(false);
	const [pending, setPending] = useState(false);
	const [uname, setUname] = useState('');
	const [tel, setTel] = useState('');
	const [message, setMessage] = useState('');
	const timeStamp = new Date().getTime();

	const postMessage = async (e, u_name, u_phone, u_note, r_u_id) => {
		e.preventDefault();
		if (u_name === '' || u_phone === '' || u_note === '') {
			alert('Пожалуйста, заполните все поля!');
			return;
		}
		if (!validatePhone(u_phone)) {
			alert('Проверьте указанный номер телефона');
			return;
		}
		setPending(true);
		try {
			const res = await fetch('/.netlify/functions/lead-create', {
				headers: {
					'content-type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({
					r_id_internal: timeStamp,
					r_u_id,
					source: 'Заявка с сайта',
					u_name,
					u_phone,
					u_note,
				}),
			});

			if (res.ok) {
				setShowOk(true);
				setTimeout(() => setShowOk(false), 2200);
			} else {
				alert('Произошла ошибка, сообщение не отправлено');
			}
		} catch (error) {
			alert('Ошибка отправки данных');
		} finally {
			setShowModal(false);
			setPending(false);
		}
	};

	function openModal() {
		setShowModal(true);
	}

	function closeModal() {
		setShowModal(false);
	}

	return (
		<>
			{showOk && <Ok />}
			<Modal
				shouldReturnFocusAfterClose={true}
				portalClassName={style.portal}
				appElement={el.current}
				isOpen={showModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel={name}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
					<div className={style.name}>
						<strong>{name}</strong>
					</div>
					<button className={style.modalClose} onClick={closeModal}>
						<i className="icon-cross"></i>
					</button>
				</div>
				<div style={{ display: 'flex', marginBottom: '12px' }}>
					<img src={photo.fluid.src} alt={name} className={style.imageModal} />
					<div className={style.descriptionModal}>
						{description}
						<div className={style.telModal}>
							<span className={style.telegram}>
								<a onClick={(e) => e.stopPropagation()} href={telegramHref}>
									<i className="icon-telegram"></i>
								</a>
							</span>
							<span className={style.whatsapp}>
								<a onClick={(e) => e.stopPropagation()} href={whatsAppHref}>
									<i className="icon-whatsapp"></i>
								</a>
							</span>
							<a onClick={(e) => e.stopPropagation()} href={telHref}>
								{telephone}
							</a>
						</div>
					</div>
				</div>
				<form className={style.form}>
					<div className={style.inputContainer}>
						<TextField
							value={uname}
							onChange={(e) => setUname(e.target.value)}
							className={style.input}
							id={id + '-form-name'}
							type="text"
							placeholder="Ваше имя"
						/>
						<TextField
							value={tel}
							onChange={(e) => setTel(e.target.value)}
							className={style.input}
							id={id + '-form-tel'}
							type="tel"
							placeholder="Ваш номер телефона"
						/>
					</div>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className={style.area}
						id={id + '-form-message'}
						type="text"
						rows={4}
						placeholder="Ваш вопрос"
					/>

					<Button
						style={{ width: '100%', height: '40px' }}
						type="submit"
						onClick={(e) => postMessage(e, uname, tel, message, uOnId)}
					>
						{pending ? 'Отправляем...' : 'Узнать'}
					</Button>
				</form>
			</Modal>

			<div
				ref={el}
				role="button"
				className={style.expert}
				tabIndex={0}
				onKeyPress={(e) => {
					if (e.key === 'Enter') openModal();
				}}
				onClick={openModal}
			>
				<div className={style.photo}>
					<img src={photo.fluid.src} alt={name} className={style.media} />
				</div>
				<div className={style.info}>
					<div
						data-sal="slide-bottom"
						data-sal-delay="100"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.name}
					>
						{name}
					</div>
					<div
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.description}
					>
						{description}
					</div>
					<div
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.tel}
					>
						<span className={style.telegram}>
							<a onClick={(e) => e.stopPropagation()} href={telegramHref}>
								<i className="icon-telegram"></i>
							</a>
						</span>
						<span className={style.whatsapp}>
							<a onClick={(e) => e.stopPropagation()} href={whatsAppHref}>
								<i className="icon-whatsapp"></i>
							</a>
						</span>
						<a onClick={(e) => e.stopPropagation()} href={telHref}>
							{telephone}
						</a>
					</div>
					<Button
						onClick={(e) => {
							e.stopPropagation();
							openModal();
						}}
					>
						Задать вопрос
					</Button>
				</div>
			</div>
		</>
	);
};

export default Expert;
