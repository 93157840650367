import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const TextField = ({ type, id, className, label, ...attrs }) => (
	<>
		{label && <label htmlFor={id}>{label}</label>}

		<input type={type} name={id} id={id} className={[className, styles.input].join(' ')} {...attrs} />
	</>
);

TextField.defaultProps = {
	type: 'text',
	className: '',
	label: null,
};

TextField.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	label: PropTypes.string,
};

export default TextField;
